import * as React from 'react';
import { cva } from 'class-variance-authority';

import { PolymorphicComponentPropsWithRef } from '@@types/AsProp';
import { clx_ } from '@@libs/utils';

import css from './card.module.scss';

/**
 * Types
 */
type CardProps<T extends React.ElementType = typeof DefaultComponent> = PolymorphicComponentPropsWithRef<
  T,
  {
    variant?: 'default' | 'glass';
  }
>;
type CardHeaderProps<T extends React.ElementType = typeof DefaultComponent> = PolymorphicComponentPropsWithRef<T, {}>;
type CardTitleProps<T extends React.ElementType = 'h3'> = PolymorphicComponentPropsWithRef<T, {}>;
type CardDescriptionProps<T extends React.ElementType = 'p'> = PolymorphicComponentPropsWithRef<T, {}>;
type CardContentProps<T extends React.ElementType = typeof DefaultComponent> = PolymorphicComponentPropsWithRef<T, {}>;
type CardFooterProps<T extends React.ElementType = typeof DefaultComponent> = PolymorphicComponentPropsWithRef<T, {}>;

/**
 * Constants
 */
const clx = clx_(css);
const DefaultComponent = 'div';

/**
 * Component: Card
 */
const Card = React.forwardRef(function Card<T extends React.ElementType = typeof DefaultComponent>(
  { as, variant, className, ...props }: CardProps<T>,
  ref: React.ForwardedRef<T>
) {
  const cardVariants = cva('rounded-lg border', {
    variants: {
      variant: {
        default: 'text-card-foreground shadow-sm bg-card',
        glass: 'variant-glass border-white/70 dark:border-white/10 bg-white/30 text-black backdrop-blur-sm dark:bg-black/25 dark:text-white',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  });
  const Component = as || DefaultComponent;
  return <Component ref={ref} className={clx(cardVariants({ variant }), className)} {...props} />;
});
//@ts-expect-error
Card.displayName = 'Card';

/**
 * Component: CardHeader
 */
const CardHeader = React.forwardRef(function CardHeader<T extends React.ElementType = typeof DefaultComponent>(
  { as, className, ...props }: CardHeaderProps<T>,
  ref: React.ForwardedRef<T>
) {
  const Component = as || DefaultComponent;
  return <Component ref={ref as any} className={clx('flex flex-col space-y-1.5 p-4', className)} {...props} />;
});
//@ts-expect-error
CardHeader.displayName = 'CardHeader';

/**
 * Component: CardTitle
 */
const CardTitle = React.forwardRef(function CardTitle<T extends React.ElementType = 'h3'>(
  { as, className, ...props }: CardTitleProps<T>,
  ref: React.ForwardedRef<T>
) {
  const Component = as || 'h3';
  return <Component ref={ref as any} className={clx('text-2xl font-semibold leading-none tracking-tight', className)} {...props} />;
});
//@ts-expect-error
CardTitle.displayName = 'CardTitle';

/**
 * Component: CardDescription
 */
const CardDescription = React.forwardRef(function CardDescription<T extends React.ElementType = 'p'>(
  { as, className, ...props }: CardDescriptionProps<T>,
  ref: React.ForwardedRef<T>
) {
  const Component = as || 'p';
  return <Component ref={ref as any} className={clx('text-sm text-muted-foreground', className)} {...props} />;
});
//@ts-expect-error
CardDescription.displayName = 'CardDescription';

/**
 * Component: CardContent
 */
const CardContent = React.forwardRef(function CardContent<T extends React.ElementType = typeof DefaultComponent>(
  { as, className, ...props }: CardContentProps<T>,
  ref: React.ForwardedRef<T>
) {
  const Component = as || DefaultComponent;
  return <Component ref={ref as any} className={clx('p-4 pt-0', className)} {...props} />;
});
//@ts-expect-error
CardContent.displayName = 'CardContent';

/**
 * Component: CardFooter
 */
const CardFooter = React.forwardRef(function CardFooter<T extends React.ElementType = typeof DefaultComponent>(
  { as, className, ...props }: CardFooterProps<T>,
  ref: React.ForwardedRef<T>
) {
  const Component = as || DefaultComponent;
  return <Component ref={ref as any} className={clx('flex items-center p-4 pt-0', className)} {...props} />;
});
//@ts-expect-error
CardFooter.displayName = 'CardFooter';

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent };
