import { z } from 'zod';

/**
 * Types
 */
export type SignInForm_Type = z.infer<typeof SignInForm_validation>;
export type VerifyEmailForm_Type = z.infer<typeof VerifyEmailForm_validation>;
export type ForgotPasswordForm_Type = z.infer<typeof ForgotPasswordForm_validation>;
export type NewPasswordForm_Type = z.infer<typeof NewPasswordForm_validation>;

/**
 * Validation: Sign in
 */
export const SignInForm_validation = z.object({
  email: z.string().email().trim(),
  password: z.string().trim().min(1, 'Field is required.').max(32),
});

/**
 * Validation: Verify email
 */
export const VerifyEmailForm_validation = z.object({
  id: z.string().trim().min(24).max(24),
  token: z.string().trim().min(32).max(36),
});

/**
 * Validation: Forgot password
 */
export const ForgotPasswordForm_validation = z.object({
  email: z.string().email().trim(),
});

/**
 * Validation: New password
 */
export const NewPasswordForm_validation = z
  .object({
    id: z.string().trim().min(24).max(24),
    token: z.string().trim().min(32).max(36),
    password: z.string().trim().min(3).max(32),
    password2: z.string().trim().min(3).max(32),
  })
  .refine((data) => data.password === data.password2, { path: ['password2'], message: "Passwords don't match" });
