import dynamic from 'next/dynamic';

import Skeleton from '@@components/ui/skeleton';

const AuthLinks = dynamic(
  async () => {
    // await new Promise((res) => setTimeout(res, 5000));
    return import('@@components/auth/AuthLinks.component');
  },
  {
    loading: () => (
      <div className="space-y-2 text-center">
        <Skeleton className="mx-auto h-6 w-[16ch]" />
        <Skeleton className="mx-auto h-9 w-[7ch]" />
      </div>
    ),
  }
);
export default AuthLinks;
