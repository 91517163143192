import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { Slot } from '@radix-ui/react-slot';
import { Controller, ControllerProps, FieldPath, FieldValues, FormProvider, useFormContext } from 'react-hook-form';

import { cn } from '@@libs/utils';
import { Label } from '@@components/ui/label';

const Form = FormProvider;

/**
 * Types
 */
type FormFieldContextValue<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
  name: TName;
  required?: boolean;
};
type FormItemContextValue = {
  id: string;
  required?: boolean;
};
type FormItemProps = React.HTMLAttributes<HTMLDivElement> & {
  required?: boolean;
};
type FormFieldProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = ControllerProps<
  TFieldValues,
  TName
> & {
  required?: boolean;
};

/**
 * Utils
 */
const InputCloneFn = (children: React.ReactNode, required?: boolean) => {
  if (!children) return null;
  if (Array.isArray(children))
    return React.Children.toArray(children).map((child: any) => {
      if (child?.type?.displayName === 'Input' && required) return React.cloneElement(child, { ...child?.props, required });
      return child;
    });
  if (!Array.isArray(children)) return React.cloneElement(children as React.ReactElement, { ...(children as React.ReactElement)?.props, required });
};

/**
 * Contexts
 */
const FormFieldContext = React.createContext<FormFieldContextValue>({} as FormFieldContextValue);
const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue);

/**
 * Hook: useFormField
 */
const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();
  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) throw new Error('useFormField should be used within <FormField>');

  return {
    ...fieldState,
    ...itemContext,
    name: fieldContext.name,
    formItemId: `${itemContext.id}-form-item`,
    formDescriptionId: `${itemContext.id}-form-item-description`,
    formMessageId: `${itemContext.id}-form-item-message`,
  };
};

/**
 * Component: FormField
 */
const FormField = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  required,
  ...props
}: FormFieldProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name, required }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

/**
 * Component: FormItem
 */
const FormItem = React.forwardRef<HTMLDivElement, FormItemProps>(({ className, required, ...props }, ref) => {
  const id = React.useId();
  const fieldContext = React.useContext(FormFieldContext);

  return (
    <FormItemContext.Provider value={{ id, required: fieldContext.required ?? required }}>
      <div ref={ref} className={cn('', className)} {...props} />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = 'FormItem';

/**
 * Component: FormLabel
 */
const FormLabel = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>>(
  ({ className, ...props }, ref) => {
    const { error, formItemId } = useFormField();

    return (
      <Label
        ref={ref}
        className={cn(
          {
            'text-destructive': error, //
            // 'after:ml-1 after:text-lg after:font-black after:text-destructive after:content-["*"]': required,
          },
          className
        )}
        htmlFor={formItemId}
        {...props}
      />
    );
  }
);
FormLabel.displayName = 'FormLabel';

/**
 * Component: FormControl
 */
const FormControl = React.forwardRef<React.ElementRef<typeof Slot>, React.ComponentPropsWithoutRef<typeof Slot>>(({ children, ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId, required } = useFormField();
  const Children = InputCloneFn(children, required);

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-invalid={!!error}
      aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
      children={Children}
      {...props}
    />
  );
});
FormControl.displayName = 'FormControl';

/**
 * Component: FormDescription
 */
const FormDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();
  return <p ref={ref} id={formDescriptionId} className={cn('mt-1 text-sm text-muted-foreground', className)} {...props} />;
});
FormDescription.displayName = 'FormDescription';

/**
 * Component: FormMessage
 */
const FormMessage = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;
  if (!body) return null;

  return (
    <p ref={ref} id={formMessageId} className={cn('mt-1 text-sm font-medium text-destructive', className)} {...props}>
      {body}
    </p>
  );
});
FormMessage.displayName = 'FormMessage';

export { useFormField, Form, FormItem, FormLabel, FormControl, FormDescription, FormMessage, FormField };
