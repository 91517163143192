import * as React from 'react';
import { cva } from 'class-variance-authority';

import { cn } from '@@libs/utils';

/**
 * Types
 */
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  color?: 'default' | 'error';
}

/**
 * Constants
 */
const inputVariants = cva(
  'transition-all flex h-10 w-full rounded-md border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      color: {
        default: 'bg-background dark:bg-card-foreground dark:text-background ring-offset-background focus-visible:ring-ring border-input',
        error: 'bg-destructive/30 ring-offset-destructive/25 focus-visible:ring-destructive/75 border-destructive',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      color: 'default',
      // size: 'default',
    },
  }
);

/**
 * Component: Input
 */
const Input = React.forwardRef<HTMLInputElement, InputProps>(({ color, className, type, ...props }, ref) => {
  return <input type={type} className={cn(inputVariants({ color }), className)} ref={ref} {...props} />;
});
Input.displayName = 'Input';

export { Input };
